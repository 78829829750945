@tailwind base;
@tailwind components;
@tailwind utilities;

.h1 {
  @apply text-[64px] leading-[96px];
}

.h2 {
  @apply text-[56px] leading-[84px];
}

.h3 {
  @apply text-[32px] leading-[48px];
}

.h4 {
  @apply text-[24px] font-bold leading-[36px];
}

.h5 {
  @apply text-[20px] font-bold leading-[30px];
}

.body1 {
  @apply text-[20px] leading-[30px];
}

.body2 {
  @apply text-[18px] leading-[27px];
}

.body3 {
  @apply text-[16px] leading-[24px];
}

.tiny-text {
  @apply text-[12px] leading-[18px];
}

.h72 {
  letter-spacing: -1.61px;
  @apply text-[72px] leading-[90px];
}

.h64 {
  letter-spacing: -1.43px;
  @apply text-[64px] leading-[80px];
}

.h56 {
  letter-spacing: -1.25px;
  @apply text-[56px] leading-[70px];
}

.h48 {
  letter-spacing: -1.02px;
  @apply text-[48px] leading-[60px];
}

.h40 {
  letter-spacing: -0.89px;
  @apply text-[40px] leading-[50px];
}

.h36 {
  letter-spacing: -0.79px;
  @apply text-[36px] leading-[45px];
}

.h28 {
  letter-spacing: -0.52px;
  @apply text-[28px] leading-[42px];
}

.h24 {
  letter-spacing: -0.47px;
  @apply text-[24px] leading-[36px];
}

.body20 {
  letter-spacing: -0.33px;
  @apply text-[20px] leading-[30px];
}

.body18 {
  letter-spacing: -0.26px;
  @apply text-[18px] leading-[27px];
}

.body16 {
  letter-spacing: -0.18px;
  @apply text-[16px] leading-[24px];
}

.body14 {
  letter-spacing: -0.09px;
  @apply text-[14px] leading-[20px];
}

.label12 {
  letter-spacing: 0.01px;
  @apply text-[12px] leading-[18px];
}

.btn-primary {
  @apply body1 cursor-pointer rounded-xl bg-primary-600 px-6 py-4 font-semibold text-white hover:bg-primary-800 disabled:cursor-not-allowed disabled:bg-primary-200;
}

.btn-secondary {
  @apply body3 rounded-lg bg-neutral-500 px-6 py-3 text-white hover:bg-neutral-600 disabled:cursor-not-allowed disabled:bg-primary-200;
}

.btn-ghost {
  @apply body1 rounded-xl border-2 border-neutral-300 bg-white px-6 py-4 font-semibold text-neutral-600 hover:bg-neutral-300 disabled:cursor-not-allowed disabled:opacity-40;
}

.input-field {
  @apply w-full rounded-[12px] border text-neutral-800 placeholder:text-neutral-400 focus:border-neutral-400 focus:outline-0;
}

.search-value em {
  @apply !not-italic text-brand underline underline-offset-4;
}

.no-focus-outline {
  @apply focus-visible:outline-none active:outline-none active:outline-0;
}

.purple-gradient-text {
  @apply bg-purple-gradient-2 bg-clip-text text-transparent;
}

* {
  scrollbar-width: none; /* For Firefox */
}

*::-webkit-scrollbar {
  width: 0px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

.table-head {
  @apply label12 h-11 border-b px-3 text-left font-semibold text-gray-700 dark:border-dark-neutral-700 dark:bg-dark-neutral-800 dark:text-white;
}

.table-cell {
  @apply overflow-hidden px-3 py-[6px] text-left text-gray-800;
}

th {
  @apply table-head;
}

td {
  @apply table-cell;
}

tbody tr {
  @apply border-t transition dark:border-white dark:border-opacity-5;
}

.anchor-link {
  @apply text-ellipsis text-blue-500 hover:underline;
}

.relative-time {
  @apply label12 font-medium dark:text-gray-500;
}

.transaction-date {
  @apply label12 font-medium text-gray-500 dark:text-gray-200;
}

.h56-responsive {
  letter-spacing: -1.25px;
  @apply text-[56px] leading-[84px] max-sm:text-[36px] max-sm:leading-[45px] max-sm:tracking-[-0.79px];
}

.body20-responsive {
  letter-spacing: -0.33px;
  @apply text-[20px] leading-[30px] max-sm:text-[15.19px] max-sm:leading-[22.79px] max-sm:tracking-[-0.25px];
}

.body18-responsive {
  letter-spacing: -0.26px;
  @apply text-[18px] leading-[27px] max-sm:text-[16px] max-sm:leading-[24px] max-sm:tracking-[-0.18px];
}

.body16-responsive {
  letter-spacing: -0.18px;
  @apply text-[16px] leading-[24px] max-sm:text-[14px] max-sm:leading-[20px] max-sm:tracking-[-0.09px];
}

.body14-responsive {
  letter-spacing: -0.09px;
  @apply text-[14px] leading-[20px] max-sm:text-[12px] max-sm:leading-[18px];
}

.h36-responsive {
  letter-spacing: -0.79px;
  @apply text-[36px] leading-[45px] max-sm:text-[24px] max-sm:leading-[36px] max-sm:tracking-[-0.47px];
}

.h40-responsive {
  letter-spacing: -0.89px;
  @apply text-[40px] leading-[50px] max-sm:text-[24px] max-sm:leading-[36px] max-sm:tracking-[-0.47px];
}
