:root {
  --background: #0a0a0a;
  --foreground: #ededed;
  --font-sans:
    'Overused Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  --font-serif: Georgia, Times, serif;
  --font-mono: Menlo, Courier, monospace;
}

.theme-dark {
  --background: #0a0a0a;
  --foreground: #ededed;
}

.theme-light {
  --background: #ffffff;
  --foreground: #171717;
}

@font-face {
  font-family: 'Overused Grotesk';
  src:
    url('../fonts/OverusedGrotesk-VF.woff2') format('woff2 supports variations'),
    url('../fonts/OverusedGrotesk-VF.woff2') format('woff2-variations');
  font-weight: 300 900;
  font-style: normal italic;
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: 'Overused Grotesk', sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

/* styles/globals.css */
.focus-visible\:outline-none:focus-visible {
  outline: none !important; /* Remove the default outline */
}

.focus\:outline-none:focus {
  outline: none !important;
}

.focus {
  outline: none !important;
}

.Toastify__toast-body {
  align-items: start !important;
  padding: 0 !important;
  gap: 2px;
}

input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input::placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
